<template>
    <Modal
        :name="'remove-team-modal-' + team.uid"
        warning
        unavoidable
    >
        <h3>{{ $t("team_delete", [team.name]) }}</h3>
        {{ $t('team_delete_confirm_descr', [team.name]) }}
        <template #footer>
            <div class="grid">
                <div class="left"></div>
                <div class="right">
                    <div class="button-group">
                        <app-button
                            :busy="deleting"
                            type="warning"
                            @click="onDeleteBtnClick"
                        >
                            {{ $t("team_delete") }}
                        </app-button>
                        <app-button
                            :class="{disabled: deleting}"
                            class="light"
                            @click="$modal.hide('remove-team-modal-' + team.uid)"
                        >
                            {{ $t("cancel") }}
                        </app-button>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Vue from "vue";
import { DELETE_TEAM } from "@web/store/team/actions";
import { mapActions } from "vuex";
import Modal from "@web/components/modals/Modal";
import { TEAM_MODULE_NAME } from "@/store/team/team";

export default Vue.extend({
    name: "TeamRemoveModal",
    components: { Modal },
    props: {
        team: {
            type: Object
        },
    },
    data() {
        return {
            deleting: false,
        };
    },
    methods: {
        ...mapActions({ deleteTeam: TEAM_MODULE_NAME + DELETE_TEAM }),
        async onDeleteBtnClick() {
            this.deleting = true;
            await this.$emit("delete", this.team);
            this.$modal.hide("remove-team-modal-" + this.team.uid);
        },
    },
});
</script>
