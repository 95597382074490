<template>
    <Modal
        :name="modalName"
        :title="title"
        @before-open="onOpen"
    >
        <template #body>
            <form @submit.prevent="triggerAction">
                <div class="form-group">
                    <label :for="`teamname-${_uid}`">{{ $t("team_name") }}</label>
                    <input
                        :id="`teamname-${_uid}`"
                        v-model.trim="name"
                        v-autofocus
                        type="text"
                        name="teamname"
                        :disabled="saving"
                        :class="{error: $v.name.$error}"
                        :placeholder="$t('team_input_placeholder')"
                    />
                    <div
                        v-if="$v.name.$dirty && $v.name.$error"
                        class="error"
                    >
                        <div v-if="!$v.name.required">
                            {{ $t("validation_field_required") }}
                        </div>
                        <div v-if="!$v.name.uniqueName">
                            {{ $t("team_name_validation_unique") }}
                        </div>
                        <div v-if="!$v.name.minLength">
                            {{ $t("validation_field_min_length", [$v.name.$params.minLength.min]) }}
                        </div>
                        <div v-if="!$v.name.maxLength">
                            {{ $t("validation_field_max_length", [$v.name.$params.maxLength.max]) }}
                        </div>
                    </div>
                </div>
            </form>
        </template>

        <template #footer>
            <div class="grid">
                <div></div>
                <div class="right">
                    <app-button
                        class="light"
                        :disabled="saving"
                        @click="closeModal"
                    >
                        {{ $t("cancel") }}
                    </app-button>
                    <app-button
                        class="primary"
                        :disabled="$v.$invalid"
                        :busy="saving"
                        @click="triggerAction"
                    >
                        {{ title }}
                    </app-button>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import {
    CREATE_TEAM,
    UPDATE_TEAM,
} from "@web/store/team/actions";
import {
    mapActions,
    mapGetters,
} from "vuex";
import Modal from "@web/components/modals/Modal";
import { TEAM_MODULE_NAME } from "@/store/team/team";
import { i18n } from "@/i18n";
import { validationMixin } from "vuelidate";
import { GET_TEAMS } from "@web/store/team/getters";
import { name } from "@web/store/team/validators";

export default {
    name: "TeamEditModal",
    components: {
        Modal,
    },
    mixins: [validationMixin],
    props: {
        team: Object,
    },
    data() {
        return {
            saving: false,
            name: (this.team || {}).name || "",
        };
    },
    computed: {
        ...mapGetters({
            teams: TEAM_MODULE_NAME + GET_TEAMS,
        }),
        isCreateMode() {
            return this.team === undefined;
        },
        modalName() {
            if (this.isCreateMode) {
                return "add-team-modal";
            }
            return `edit-team-modal-${this.team.uid}`;
        },
        title() {
            if (this.isCreateMode) {
                return this.$t("team_add");
            }
            return this.$t("team_edit");
        },
        otherTeams() {
            if (this.team) {
                return this.teams.filter(({ uid }) => uid !== this.team.uid);
            }
            return this.teams;
        },
    },
    methods: {
        ...mapActions({
            createTeam: TEAM_MODULE_NAME + CREATE_TEAM,
            updateTeam: TEAM_MODULE_NAME + UPDATE_TEAM,
        }),
        onOpen() {
            this.$v.$reset();
        },
        closeModal() {
            this.$modal.hide(this.modalName);
        },
        async triggerAction() {
            this.$v.$touch();
            if (this.$v.$invalid) return;
            this.$v.$reset();
            try {
                this.saving = true;
                if (this.isCreateMode) {
                    await this.createTeam({ name: this.name });
                    this.name = "";
                } else {
                    await this.updateTeam({
                        teamUid: this.team.uid,
                        teamUpdatePayload: {
                            name: this.name,
                        },
                    });
                }
                this.closeModal();
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: i18n.t("team_failed_to_create"),
                });
            } finally {
                this.saving = false;
            }
        },
    },
    validations() {
        return {
            name: name(this.otherTeams),
        };
    },
};
</script>
