<template>
    <div
        v-if="team"
        class="team-row"
        :class="classObject"
    >
        <div class="team-name">
            <team-link
                v-bind="team"
                class="hover-underline"
            >
                {{ team.name }}
            </team-link>
            <Spinner
                v-if="startDeleting"
                class="mleft-small"
                inline
            />
        </div>

        <div>
            <v-popover
                v-if="isAdmin"
                ref="popover"
                popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
                hide-on-target-click="true"
                placement="top-end"
            >
                <app-button
                    type="action-menu-button light only-icon"
                    icon="ellipsis"
                    bg="none"
                    :disabled="startDeleting"
                />
                <template #popover>
                    <div
                        v-close-popover
                        class="actions"
                    >
                        <app-button
                            type="link no-padding small"
                            class="edit-action"
                            icon="edit"
                            @click="onClickOnEditBtn"
                        >
                            {{ $t("edit") }}
                        </app-button>
                        <app-button
                            type="link no-padding small"
                            class="delete-action"
                            icon="trash"
                            @click="onClickOnDeleteBtn"
                        >
                            {{ $t("delete") }}
                        </app-button>
                    </div>
                </template>
            </v-popover>
            <team-edit-modal :team="team"/>
            <team-remove-modal
                :team="team"
                @delete="triggerDelete"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { IS_ADMIN } from "@web/store/intranet/getters";
import TeamRemoveModal from "@web/views/intranet/directory/teams/TeamRemoveModal";
import TeamEditModal from "@/views/intranet/directory/teams/TeamEditModal";
import Spinner from "@/components/Spinner";
import TeamLink from "@/components/team/TeamLink";

export default Vue.extend({
    name: "TeamRow",
    components: {
        Spinner,
        TeamRemoveModal,
        TeamEditModal,
        TeamLink,
    },
    props: {
        team: {
            type: Object,
        },
    },
    data() {
        return {
            startDeleting: false,
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [IS_ADMIN]),
        classObject() {
            return {
                "collapsible": this.isAdmin,
                "not-collapsible": !this.isAdmin,
                "start-deleting": this.startDeleting,
            };
        },
    },
    methods: {
        triggerDelete(team) {
            this.startDeleting = true;
            this.$emit("delete", team);
        },
        onClickOnEditBtn() {
            this.$modal.show(`edit-team-modal-${this.team.uid}`);
        },
        onClickOnDeleteBtn() {
            this.$modal.show("remove-team-modal-" + this.team.uid);
        },
    },
});
</script>

<style lang="scss" scoped>
.team-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    border-bottom: 1px solid rgba(black, 0.1);
    padding: 10px 0;

    &.start-deleting {
        color: var(--highest-contrast);
    }

    .team-name {
        flex: 1 0 auto;
    }

    ::v-deep {
        .v-popover {
            .trigger {
                cursor: pointer;
            }
        }

        .action-menu-button {
            padding-top: 0;
            padding-bottom: 0;
            vertical-align: middle;
        }
    }
}

.actions {
    display: flex;
    flex-direction: column;

    .button {
        margin: 0
    }
}
</style>
