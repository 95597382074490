<template>
    <div class="pbottom-large">
        <div class="mbottom-medium grid">
            <div></div>
            <div class="right">
                <app-button
                    v-if="isAdmin"
                    type="primary"
                    icon="plus"
                    @click="openAddTeamModal"
                >
                    {{ $t("team_add") }}
                </app-button>
            </div>
        </div>
        <loading-wrapper :is-loading="initialLoading">
            <div class="team-list">
                <transition-group name="infinite-list-group">
                    <team-row
                        v-for="(team, i) in teams"
                        :key="team.uid"
                        :team="team"
                        :style="{'--delay': i * 50 + 'ms'}"
                        @delete="triggerDelete"
                    />
                </transition-group>
                <template v-if="teams.length === 0">
                    <system-message
                        v-if="isAdmin"
                        key="empty"
                        type="topRightArrow"
                        :title="$t('teams_empty_list_title')"
                        :text="$t('teams_empty_list_text')"
                    />
                    <system-message
                        v-if="!isAdmin"
                        key="empty"
                        type="results"
                        :title="$t('teams_empty_list_title')"
                        text=""
                    />
                </template>
                <infinite-scroll-spinner
                    v-if="hasMore"
                    key="spinner"
                    :loading="fetching"
                    @load-more="triggerLoadMore"
                />
            </div>
        </loading-wrapper>
        <team-edit-modal/>
    </div>
</template>

<script>
import Vue from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import TeamRow from "@web/views/intranet/directory/teams/TeamRow";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";
import { TEAM_MODULE_NAME } from "@web/store/team/team";
import {
    DELETE_TEAM,
    FETCH_TEAMS,
    RESET_TEAMS
} from "@web/store/team/actions";
import TeamEditModal from "@/views/intranet/directory/teams/TeamEditModal";
import {
    FETCHING,
    GET_TEAMS,
    HAS_MORE,
    INITIALLY_LOADED
} from "@/store/team/getters";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { IS_ADMIN } from "@/store/intranet/getters";

export default Vue.extend({
    name: "Teams",
    components: {
        TeamRow,
        InfiniteScrollSpinner,
        TeamEditModal,
    },
    computed: {
        ...mapGetters({
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            teams: TEAM_MODULE_NAME + GET_TEAMS,
            hasMore: TEAM_MODULE_NAME + HAS_MORE,
            fetching: TEAM_MODULE_NAME + FETCHING,
            initiallyLoaded: TEAM_MODULE_NAME + INITIALLY_LOADED,
        }),
        initialLoading() {
            return this.fetching && !this.initiallyLoaded;
        },
    },
    methods: {
        ...mapActions({
            resetTeams: TEAM_MODULE_NAME + RESET_TEAMS,
            loadTeams: TEAM_MODULE_NAME + FETCH_TEAMS,
            deleteTeam: TEAM_MODULE_NAME + DELETE_TEAM,
        }),
        triggerDelete(team) {
            this.deleteTeam({ teamUid: team.uid });
        },
        openAddTeamModal() {
            this.$modal.show("add-team-modal");
        },
        triggerLoadMore() {
            this.loadTeams();
        },
    },
    created() {
        this.resetTeams();
        this.loadTeams();
    },
});

</script>

<style lang="scss" scoped>
</style>
