// this is impossible to avoid as vuelidate augments the namespace
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../node_modules/@types/vuelidate/vue.d.ts" />
import {
    CustomRule,
    maxLength,
    minLength,
    required,
} from "vuelidate/lib/validators";
// augmented by vuelidate
import { RuleDecl } from "vue/types/options";
import { getGlobalConfiguration } from "@web/global-config";
import { Team } from "@backend/team/types";

function uniqueName(teams: Team[]): CustomRule {
    return (value) => teams.every(({ name }) => name !== value);
}

export function name(teams: Team[]): RuleDecl {
    return {
        required,
        minLength: minLength(getGlobalConfiguration().input_validation_team_name_min_length),
        maxLength: maxLength(getGlobalConfiguration().input_validation_team_name_max_length),
        uniqueName: uniqueName(teams),
    };
}
